.sidebar {
  // flex-basis: 240px;
  background-color: #fff;
  z-index: 2;
  width: 69px;
  transition: width 0.15s ease-in-out;
  position: fixed;
  height: 100%;

  &--open, &--unfolded{
    width: 240px;
  }
  &--open {
    .sidebar__nav{
      width: 100%;
    }
  }

  &__burger-wrap{
    padding: 15px;
    cursor: pointer;
    user-select: none;
  }
  &__burger{
    width: 18px;

    span{
      display: block;
      width: 100%;
      border-radius: 3px;
      height: 2px;
      background: #535353;
      transition: all .3s;
      position: relative;

      &:nth-child(1) {
        animation: ease .6s top forwards;
      }
      &:nth-child(2) {
        animation: ease .6s scaled forwards;
      }
      &:nth-child(3) {
        animation: ease .6s bottom forwards;
      }
      & + span {
        margin-top: 4px;
      }
    }

    &--active{

      span {
  
        &:nth-child(1) {
          animation: ease .6s top-2 forwards;
        }
        &:nth-child(2) {
          animation: ease .6s scaled-2 forwards;
        }
        &:nth-child(3) {
          animation: ease .6s bottom-2 forwards;
        }
      }
    }
  }
  &__header {
    border-right: 1px solid #f2f4f9;
    height: 59px;
    display: flex;
    align-items: center;
    font-size: 10px;
    padding-left: 10px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }

  &__clock {
    position: absolute;
    left: 6px;
    bottom: 0;
    margin-left: 0;
    transition: all 0.2s ease-in-out;
    font-weight: bold;
    font-size: 13px;

    &--open {
      margin-left: 50px;
      font-size: 14px;
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    margin-right: 15px;
  }

  &__logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  &__title {
    margin: 0;
  }

  &__nav {
    height: 100%;
    width: 69px;
    padding-top: 10px;
    border-top: 1px solid #f2f4f9;
    border-right: 1px solid #f2f4f9;
    box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
    transition: width .15s ease-in-out;
    background-color: #fff;

    &--unfolded{
      width: 240px;
    }
  }

  &__list {
    margin: 0;
    color: #000;
    padding: 0;

  }
  
  &__list-item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;

    & > a {
      border-left: 3px solid transparent;
      padding: 6px 23px;
    }

    & svg {
      color: #000;
      transition: 0.13s all;
    }
    
    & span {
      text-decoration: none;
      color: #000;
      transition: 0.12s all;
      width: 100%;
      position: absolute;
      left: 60px;
      animation: fadeIn 0.3s cubic-bezier(0.5, 0, 0.75, 0);
    }

    &:hover {
      & span {
      color: #727cf5;
      }
  
      & svg {
        fill: #727cf5;
        color: #727cf5;
      }
    }
    &--active{

      & > a {
        border-left: 3px solid #727cf5;
      }
      .sidebar__list-item--collapse {
        border-left: 3px solid #727cf5;
      }
    }
    &--active,
    &--open{
      span {
        color: #727cf5;
      }
      svg {
        fill: #727cf5;
        color: #727cf5;
      }

      div.sidebar__list-item--collapse-show{
        display: block;
      }
    }

    &--open{

      ul.collapse-list{
        max-height: 200px;
        margin: 6px 23px;
        padding: 0 0 10px 33px;
        opacity: 1;
        animation: fadeIn 0.30s cubic-bezier(0.5, 0, 0.75, 0);
      }
    }

    &--collapse{
      padding: 6px 23px;
      border-left: 3px solid transparent;

      span{
        width: 190px;
      }

      &-icon{
        position: absolute;
        right: 20px;
        font-size: 13px;
      }
    }

    &--collapse-wrap{
      display: block;

      .collapse-list{
        height: auto;
        max-height: 0px;
        opacity: 0;
        margin: 0;
        padding: 0;
        margin: 0 30px;
        padding: 0 0 0 33px;
        transition: all 0.25s ease-in;
  
        .collapse-list-item{
          position: relative;
          height: 25px;
  
          a{
            display: block;
            padding: 0;
            color: #000;
            font-size: 15px;
            text-decoration: none;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
  
            &::before{
              content: '';
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: transparent;
              border: 1px solid #a9b4ca;
              position: absolute;
              left: -29px;
              top: 5px;
              transition: all 0.4s ease-in-out;
            }         
          }
  
          &:hover{
  
            a{
              color: #727cf5;
              margin-left: 3px;
            }
  
            a::before{
              border: 1px solid #727cf5;
              background: #727cf5;
            }
          }
  
          &--active{
            
            a{
              color: #727cf5;
              margin-left: 3px;
            }
  
            a::before{
              border: 1px solid #727cf5;
              background: #727cf5;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn{
  0% {
    opacity: 0;
  }

  50%{
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes top {
  0% { top: 0; transform: rotate(0); }
  50% { top: 6px; transform: rotate(0); }
  100% { top: 6px; transform: rotate(45deg); }
}

@keyframes top-2 {
  0% { top: 6px; transform: rotate(45deg); }
  50% { top: 6px; transform: rotate(0deg); }
  100% { top: 0; transform: rotate(0deg); }
}

@keyframes bottom {
  0% { bottom: 0; transform: rotate(0); }
  50% { bottom: 6px; transform: rotate(0); }
  100% { bottom: 6px; transform: rotate(135deg); }
}

@keyframes bottom-2 {
  0% { bottom: 6px; transform: rotate(135deg); }
  50% { bottom: 6px; transform: rotate(0); }
  100% { bottom: 0; transform: rotate(0); }
}

@keyframes scaled {
  50% { transform: scale(0); }
  100% { transform: scale(0); }
}

@keyframes scaled-2 {
  0% { transform: scale(0); }
  50% { transform: scale(0); }
  100% { transform: scale(1); }
} 