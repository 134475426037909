body{
  background-color: #E6EBF1;
}

.login {
  font-family: Roboto, sans-serif;
  color: #808080;
  display: flex;
  height: 560px;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.12);
  width: 734px;
  margin: auto;
  margin-top: 10vh;

  &__spinner {
    width: 30px;
    display: flex;
    align-items: center;
  }

  &__info{
    background-color: #F6F9FC;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 314px;
    border-radius: 6px 0 0 6px;
    justify-content: flex-start;
    padding: 30px 30px 0;
    font-size: 12px;
  }
  &__title{
    font-size: 24px;
    font-weight: bold;
  }
  &__subtitle{
    font-size: 18px;
    line-height: 15px;
    font-weight: 400;
  }
  // &__sign-in{
  //   color: #0092F2;
  //   background: none;
  //   border: none;
  //   align-self: flex-start;
  //   margin: 15px 0 25px;
  //   padding: 0;
  // }
  &__info-list{
    padding: 0;
    margin: 15px 0 0;
    list-style: none;
  }
  &__info-list-item{
    line-height: 18px;

    &::before{
      content: '> ';
    }
  }
  &__text-bottom{
    margin: auto auto 10px 0px;
  }
  &__form{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 420px;
    border-radius: 0 6px 6px 0;
    padding-top: 40px;
    position: relative;
  }
  &__form-field{
    width: 80%;
    margin-left: 10%;
    margin-top: 18px;
  }
  &__label{
    display: block;
    color: #000001;
    letter-spacing: 1px;
    font-size: 12px;
  }
  &__input-wrap{
    position: relative;
    margin: 10px 0px 20px;
  }
  &__input{
    background-color: #fff;
    border: 2px solid #00B936;
    border-radius: 4px;
    font-size: 14px;
    color: #000001;
    padding: 10px;
    padding-right: 30px;
    width: 100%;
    height: 100%;
    letter-spacing: 1px;
    outline: none;
  }
  &__check{
    position: absolute;
    border-top: 4px solid #00B936;
    border-right: 4px solid #00B936;
    width: 18px;
    height: 10px;
    right: 10px;
    top: 14px;
    transform: rotate(125deg);
  }
  &__recaptcha{
    margin-left: 60px;
  }
  &__btn{
    border: 2px solid #01DF85;
    background-color: #fff;
    border-radius: 6px;
    padding: 0;
    box-sizing: border-box;
    font-size: 14px;
    width: 40%;
    margin-left: 30%;
    margin-top: 20px;
    height: 50px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus, &:active {
      border: 2px solid #0092F2;
    }

  }

  & .invalid-feedback {
      bottom: unset;
      left: unset;
    }

  &__container {
    width: 100%;
    display: flex;
  }
  &__error {
    position: absolute;
    width: 100%;
    text-align: center;
    color: red;

    &--hidden {
      visibility: hidden;
      z-index: -1;
      min-height: 18px;
      display: none;
    }
  }
}