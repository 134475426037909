/* overpass-regular - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  src: local('Overpass Regular'), local('Overpass-Regular'),
    url('/media/fonts/overpass-regular/overpass-v4-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/media/fonts/overpass-regular/overpass-v4-latin-regular.woff') format('woff');   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* overpass-600 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 600;
  src: local('Overpass SemiBold'), local('Overpass-SemiBold'),
    url('/media/fonts/overpass-semibold/overpass-v4-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/media/fonts/overpass-semibold/overpass-v4-latin-600.woff') format('woff');   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* overpass-700 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  src: local('Overpass Bold'), local('Overpass-Bold'),
    url('/media/fonts/overpass-bold/overpass-v4-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/media/fonts/overpass-bold/overpass-v4-latin-700.woff') format('woff');   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}