.access-denied{
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title{
    font-size: 24px;
    text-align: center;
  }
  &__btn-home{
    font-size: 16px;
    font-weight: 400;
  }
}