.player {
  width: 100%;
  height: 30px;
  padding: 9px 0 7px;
  background-color: #eee;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);


  &__audio-source {
    width: 0;
    height: 0;
    display: none;
  }

  &__spinner {
    margin: auto;
    margin-top: 0px;
  }

  &__control {
    cursor: pointer;
    position: relative;
    width: 18px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    &--play {
      position: relative;
      height: 10px;

      &::after {
        content: "";
        margin: 0 5px;
        cursor: pointer;
        position: absolute;
        border: 6px solid transparent;
        border-left: 12px solid #00a2ff;
      }
    }

    &--pause {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 12px;
        background: linear-gradient(to right,
            #00a2ff 0% 40%,
            transparent 40% 60%,
            #00a2ff 60% 100%);
      }
    }

    &--volume {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 25%;
      max-width: none;
      margin-right: 5px;
      user-select: none;

      .player__slider {
        margin-left: 5px;
      }
    }
  }

  &__time {
    font-size: 12px;
    color: #444;
    margin: 0px 5px;

    &:last-child {
      margin-right: 15px;
    }
  }

  &__slider {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    user-select: none;
  }

  &__slider-left-path {
    height: 5px;
    background-color: #00a2ff;
    border-radius: 6px;
    cursor: pointer;
  }

  &__slider-right-path {
    flex-grow: 1;
    height: 5px;
    background-color: #696969;
    border-radius: 6px;
    cursor: pointer;
  }

  &__thumb {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #00a2ff;
    border-radius: 100%;
    cursor: pointer;
  }
}
