.lazy-load__wrap{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img {
    border-radius: 50%;
  }
}