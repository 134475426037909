@import './fonts.scss';

body {
  margin: 0;
  font-family: Overpass, sans-serif;
  color: #212529;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Reusable Components

$table-border-color: #e8ebf1;

.btn{
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.15s;

  &--danger{
    background-color: #ff3366;
    color: #fff;

    &:hover{
      background-color: darken(#ff3366, 10);
    }
  }
  &--success{
    color: #fff;
    background-color: #10b759;

    &:hover{
      background-color: darken(#10b759, 2);
    }
    &:active{
      background-color: darken(#10b759, 2);
    }
  }
  &--warning{
    color: #212529;
    background-color: #fbbc06;

    &:hover{
      background-color: darken(#fbbc06, 2);
    }
  }
  &--primary{
    color: #fff;
    background-color: #727cf5;

    &:hover{
      background-color: darken(#727cf5, 3);
    }
  }
  &--light{
    color: #212529;
    background-color: #ececec;

    &:hover{
      background-color: #d9d9d9;
    }
    &:active{
      background-color: lightgray;
    }
  }
  &:disabled{
    background-color: #7987a1;
    cursor: not-allowed;
    opacity: 0.65;
    color: #fff;

    &:hover{
      background-color: #7987a1;
    }
  }
}

.table{
  flex-grow: 1;
  
  &__load-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // TODO: refactor styles like in strongbond-superadmin
  // with suffix "-new" - it's  new styles 
  &__header-new {
    display: flex;
    align-items: baseline;
    border: 1px solid $table-border-color;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
   
  }

  &__header-block-new {
    position: relative;
  }

  &__row {
    display: flex;
    align-items: center;
    border-right: 1px solid $table-border-color;
    border-bottom: 1px solid $table-border-color;
    border-left: 1px solid $table-border-color;
    font-size: 14px;
    max-height: 60px;
    height: 60px;
    padding: 5px;
    
    &--auto-height {
      height: auto;
      max-height: none;
      // padding: 5px;
    }
  }

  &__row-item {

    & > input:disabled {
      border: 1px solid transparent;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 8px 0;
    }
  }

  &__header {
    display: flex;
    border: 1px solid $table-border-color;

    padding: 10px 15px;
    font-size: 16px;
    font-weight: 700;
  }

  &__header-block {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &--sort {
      cursor: pointer;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      &:hover{
        background-color: rgba(232, 235, 241, 0.5);
      }
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid $table-border-color;
    border-top: none;
    font-size: 14px;
    max-height: 60px;
    height: 60px;
    padding: 15px;

    &--auto-height{
      height: auto;
      max-height: none;
    }
  }

  &__item-block {
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    word-break: break-word;
  }

  &__item-actions{
    position: relative;
    margin: auto;
    margin-right: 0px;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  // Classes for List and SearchableList components
  &__list-container{
    height: 65vh;
  }

  &__list{
    padding: 0;
    margin: 0;
  }

  &__no-items{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bfbfbf;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
  }

  &__sort-btn {
  position: relative;
  color: inherit;

  &:before {
    opacity: 0.3;
    content: "\2191";
  }

  &:after {
    opacity: 0.3;
    content: "\2193";
  }

  &--asc:before {
    opacity: 1;
  }
  &--desc:after {
    opacity: 1;
  }
}
}

.select{
  border: 1px solid $table-border-color;
  background: #fff;
  outline: none;
  max-width: 150px;
  height: 33px;
  font-size: 14px;
  color: #000;

  &:disabled{
    -moz-appearance:none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    max-width: 150px;
    border: 1px solid transparent;

    //for IE
    &::-ms-expand {
      display: none;
    }
  }
}


.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
  background-color: #fff;
  padding: 15px;
}

.page-title {
  margin-top: 0;
  font-size: 24px;
}

.form-field{
  position: relative;
  display: flex;
  flex-direction: column;

  &--static{
    position: static;
  }
}
.input-label{
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}

.input {
  width: 100%;
  border: 1px solid #e8ebf1;
  outline: none;
  background: #fff;
  border-radius: 2px;
  padding: 6px;
  font-size: 14px;
  color: #495057;

  &:read-only{
    border: 1px solid transparent;
    background: transparent;
    // padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: black;
  }
  &:focus:not(:read-only){
    border-color: #80bdff;
  }
  &::placeholder{
    color: lightgray;
  }
  &--error{
    border-color: #dc3545;
  }
}
.input-wrap{
  width: 100%;
  position: relative;
}
.input-error-wrap{
  position: absolute;
  bottom: calc(0px - 3px);
  z-index: 1;
  width: 100%;
}
.input-error-text{
  color: #dc3545;
  font-size: 12px;
  position: absolute;
  left: 0px;
}
.textarea{
  width: 100%;
  resize: none;
  border: 1px solid #e8ebf1;
  padding: 5px 5px 0;
  box-sizing: content-box;
  outline: none;

  &:focus{
    border-color: #80bdff;
  }
  &:read-only{
    border: none;
  }

  &::-webkit-scrollbar{
    width: 4px;
  }
  &::-webkit-scrollbar-track{
    background: transparent;
  }
  &::-webkit-scrollbar-track-piece{
    background: transparent;
  }
  &::-webkit-scrollbar-thumb{
    background: #dbdee4;
    border-radius: 6px;
  }
}
.switch{
  font-size: 14px;

  &__btn{
    border: 1px solid #727cf5;
    border-left: none;
    color: #727cf5;
    transition: all .2s;
    padding: 6px 10px 4px;
    font-weight: 600;

    &:first-child{
      border-radius: 4px 0 0 4px;
      border-left: 1px solid #727cf5;
    }
    &:last-child{
      border-radius: 0 4px 4px 0;
    }
    &:hover{
      background-color: #727cf5;
      color: #fff;
    }
    &--active{
      background-color: #727cf5;
      color: #fff;

      &:hover{
        background-color: #4e5bf2;
        border-color: #4e5bf2;
      }
    }
  }
}

.form-error-text {
  color: #dc3545;
  font-size: 12px;
  position: absolute;
  left: 0px;

  &__wrap {
    position: absolute;
    bottom: calc(0px - 3px);
    z-index: 1;
    width: 100%;
  }
}

.file-input{
  display: none;
  // width: 0;
  // height: 0;
  // padding: 0;
  // margin: 0;
  // visibility: hidden;
}

.spinner{
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid black;
  border-right-color: transparent !important;
  border-radius: 50%;
  animation: spinner .75s linear infinite;
  position: absolute;

  &--black{
    border-color: black;
  }
  &--red{
    border-color: #ff3366;
  }
  &--green{
    border-color: #10b759;
  }
  &--yellow{
    border-color: #fbbc06;
  }
  &--blue{
    border-color: #727cf5;
  }
}

a {
  word-break: break-word;
  color: #0092F2;
}

a:visited {
  color: #727cf5;
}

.react-select {
  $this: &;

  #{$this}__control {
    box-shadow: none;
    background-color: transparent;
    min-height: auto;

    &--is-focused {
      border-color: #727cf5;

      &:hover {
        border-color: #727cf5;
      }
    }
  }

  #{$this}__dropdown-indicator {
    padding: 5px;
  }

  #{$this}__menu {
    width: max-content;
    min-width: 100%;
  }

  #{$this}__option {
    font-size: 14px;
    
    &:hover {
      background-color: rgba(#727cf5, 0.2);
    }

    &--is-selected {
      color: #fff;
      background-color: #727cf5;

      &:hover {
        background-color: #727cf5;
      }
    }
  }

  #{$this}__single-value {
    font-weight: 500;
    line-height: 15px;
    font-size: 14px;
  }
}

// Animations

.animated-init{
  animation: slideInRight 0.5s;
}
.animated-init-left{
  animation: slideInLeft 0.5s;
}
.animated-init-bottom{
  animation: slideInBottom 0.5s;
}
.fade-out{
  animation: fadeOut 0.5s;
}

@keyframes slideInRight{
  from {
    transform: translate3d(100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft{
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInBottom{
  from {
    opacity: 0;
    transform: translate3d(0, 40%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes spinner{
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOut{
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}