.welcome {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;

  .react-grid-placeholder {
    background-color: #727cf5;
    border: 1px solid blue;
  }

  .react-draggable-dragging {
    box-shadow: 0 12px 24px rgba(2, 15, 38, 0.2);
  }

  .react-resizable-handle {
    transform: rotate(-45deg);
    bottom: -6px;
    right: -6px;
    opacity: 0;
  }

  .react-grid-item {

    &:hover>.react-resizable-handle {
      opacity: 1;
    }

    &>.react-resizable-handle {
      width: auto;
      height: 25px;
      padding: 0;
      background: none;

      &::after {
        content: '';
        display: block;
        position: static;
        width: 25px;
        height: 25px;
        border: none;
        background: url(/media/sprite-svg.svg#resize) center / 25px no-repeat;
      }
    }

    &.resizing {

      .react-resizable-handle {
        opacity: 1;
      }
    }
  }

  &__title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    margin: 0 0 40px;
  }

  &__manage-widgets-wrap {
    position: relative;
  }

  &__manage-widgets {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    background-color: #fff;
    padding: 15px 0;
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 1;
  }

  &__manage-widgets-group {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__manage-widgets-group-title {
    font-size: 12px;
    color: #777777;
    padding-left: 5px;
  }

  &__manage-widgets-item {
    font-size: 14px;
    padding: 10px 20px 10px 40px;
    font-weight: 400;
    white-space: nowrap;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #f0f4f7;
      cursor: pointer;
    }
  }

  &__manage-widgets-selected {
    position: absolute;
    left: 20px;
    font-size: 12px;
  }

  &__item {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__item-title {
    margin: 0;
    padding: 15px 15px 0;
    font-size: 18px;
    cursor: grab;
    transition: background-color 0.25s;

    &:hover {
      background-color: #ececec;
    }
  }

  &__data {
    position: relative;
    min-height: 40px;
  }

  &__spinner-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-15px, -15px)
  }

  &__count-container {
    display: flex;
    justify-content: space-around;
  }

  &__count-wrap {
    // margin-right: 50px;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    span {
      text-align: center;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__count {
    font-weight: 600;
    margin-top: 5px;
    font-size: 18px;
    text-align: center;

    &--online {
      color: #9abe29;
    }

    &--away {
      color: #fecb1c;
    }

    &--busy {
      color: #ff6892;
    }
  }

  &__not-draggable {
    padding: 20px;
    height: 100%;
  }
}

.welcome-grid-layout {
  width: 100%;
  margin: auto;

  &--not-animated {

    .react-grid-item.cssTransforms {
      transition-property: none;
    }
  }
}

.operators-widget {
  min-height: 41px;
}

.calls-widget {
  min-height: 41px;
}

.incoming-calls-widget {
  position: relative;
  width: 100%;
  height: inherit;
  padding-bottom: 0;
  text-align: center;

  &__chart {
    position: relative;
    margin-top: 15px;
    padding: 0 20px;
    width: 100%;
    height: calc(100% - 65px);
  }

  &__switch {
    display: flex;
    justify-content: center;
  }

  &__empty {
    padding-bottom: 40px;
    color: #777777;
  }
}

.incoming-calls-waiting-widget {
  position: relative;
  min-height: 89px;

  &__switch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.last-calls-widget {
  width: 100%;
  position: relative;

  &__table-block-icon {
    width: 3%;
  }

  &__table-block-client {
    width: 15%;
  }

  &__table-block-operator {
    width: 17%;
  }

  &__table-block-date {
    width: 17%;
  }

  &__table-block-status {
    width: 13%;
  }

  &__table-block-direction {
    width: 13%;
  }

  &__table-block-duration {
    width: 13%;
  }

  &__table-block-actions {
    width: 10%;
  }

  &__client-name {
    cursor: pointer;
    text-decoration: underline;
    color: #727cf5;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__open-player {
    font-size: 8px;
  }

  &__table-item-wrap {
    background-color: #fff;
    border-bottom: 1px solid #e8ebf1;
    height: 61px;
    overflow: hidden;

    &--open {
      animation: openLastCallsWidgetItem .5s;
    }

    &--close {
      animation: closeLastCallsWidgetItem .5s;
    }
  }

  &__call-wrap {
    padding: 15px;
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
  }

  &__call {
    width: 50%;
  }

  .table {
    position: relative;
    overflow: hidden;

    &__list-container {
      min-height: 380px;
      max-height: 1250px;
      height: 100%;
      padding-bottom: 75px;
    }

    &__item {
      border-bottom: none;
    }
  }

  &__arrow-icon {
    margin: 0 5px;
    font-size: 10px;
  }

  &__missed-icon {
    color: #fa9090;
  }
}

.last-calls-widget-client {
  position: absolute;
  background-color: #e8f6ff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  top: 73px;
  right: 16px;
  z-index: 5;
  border-radius: 4px;
  padding: 10px;
  display: flex;

  &__left-container {
    width: 150px;
  }

  &__right-container {
    width: 250px;
    padding-left: 15px;
  }

  &__avatar {
    width: 100%;
    display: flex;
    justify-content: center;

    .lazy-load__wrap {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  &__name {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
  }

  &__info-block {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  &__info-block-title {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 5px;
  }

  &__info-block-item {
    font-size: 14px;
    margin-top: 5px;
    display: flex;

    &:nth-child(2) {
      margin-top: 10px;
    }

    &--active {
      color: #727cf5;
    }
  }

  &__info-item-icon {
    margin-right: 15px;
    color: #808080;
  }

  &__tags-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    border-radius: 4px;
    padding: 2px 5px 1px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #e6e6e6;

    &:last-child {
      margin-right: 0;
    }
  }


}

.calls-duration-widget {
  position: relative;

  &__switch {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.missed-calls-widget {
  width: 100%;

  &__table-block-client,
  &__table-block-resolved-after,
  &__table-block-missed-date-time {
    width: 18%;
  }

  &__table-block-voicemail,
  &__table-block-operator {
    width: 18%;
  }

  &__table-block-status {
    width: 10%;

    .missed-calls-widget__status {
      width: 22px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #00b900;
      border-radius: 50%;
    }

    .missed-calls-widget__status-icon {
      color: #00b900;
    }
  }

  &__table-block-client {
    justify-content: flex-start;

    .lazy-load__wrap {
      width: 30px;
      height: 30px;
      margin-right: 5px;

      img {
        border-radius: 50%;
      }
    }
  }

  &__client-name {
    cursor: pointer;
    text-decoration: underline;
    color: #727cf5;
    margin-left: 5px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__open-player {
    font-size: 8px;
  }

  &__table-item-wrap {
    background-color: #fff;
    border-bottom: 1px solid #e8ebf1;
    height: 61px;
    overflow: hidden;

    &--open {
      animation: openLastCallsWidgetItem .5s;
    }

    &--close {
      animation: closeLastCallsWidgetItem .5s;
    }
  }

  &__call-wrap {
    padding: 15px;
    border-left: 1px solid #e8ebf1;
    border-right: 1px solid #e8ebf1;
  }

  &__call {
    width: 50%;
  }

  .table {
    position: relative;
    overflow: hidden;

    &__list-container {
      min-height: 366px;
      max-height: 1250px;
      height: 100%;
      padding-bottom: 75px;
    }

    &__item {
      border-bottom: none;
    }
  }
}


.clients-statistics-widget {
  width: 100%;
  height: inherit;
  position: relative;

  &__chart {
    position: relative;
    height: inherit;
    padding-left: 15px;
    padding-right: 30px;
  }

  &__chart-wrap {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    margin: auto;
  }

  &__calendar-and-intervals {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__calendar-wrap {
    position: relative;
    min-width: 238px;
    text-align: right;

    &::after {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      background-color: lightgray;
      position: absolute;
      left: 0;
      bottom: -2px;
    }

    .calendar {
      top: 25px;
      left: auto;
      right: 0;
    }
  }

  &__open-indicator {
    color: lightgray;
  }

  &__date-icon {
    margin: 0 5px;
    color: #000;
  }

  &__pending {
    position: absolute;
    left: 12px;
    top: 15px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes openLastCallsWidgetItem {
  0% {
    height: 61px;
  }

  100% {
    height: 121px;
  }
}

@keyframes closeLastCallsWidgetItem {
  0% {
    height: 121px;
  }

  100% {
    height: 61px;
  }
}