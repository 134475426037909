.time-intervals-switcher {
  font-size: 14px;
  display: flex;

  &__btn {
    border: 1px solid #727cf5;
    border-left: none;
    color: #727cf5;
    transition: all .2s;
    padding: 6px 10px 4px;
    font-weight: 600;

    &:hover {
      background-color: #727cf5;
      color: #fff;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background-color: transparent;
        color: #727cf5;
      }
    }

    &--active {
      background-color: #727cf5;
      border-color: #727cf5;
      color: #fff;

      &:hover {
        background-color: #4e5bf2;
        border-color: #4e5bf2;
      }
    }

    &--interval {
      &:first-child {
        border-radius: 4px 0 0 4px;
        border-left: 1px solid #727cf5;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }
    }

    &--sum {
      margin-left: 10px;
      border: 1px solid #727cf5;
      border-radius: 4px;
    }
  }
}