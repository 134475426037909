.main-page {
  display: flex;
  background-color: #f8f8f8;
  min-height: 100vh;

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__settings-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 40px;
    margin-bottom: 0;
    margin-top: 99px;
  }

  .sidebar ~ .main-page__wrapper{
    margin-left: 69px;
  }
  .sidebar--open ~ .main-page__wrapper{
    margin-left: 240px;
  }
  .sidebar--open ~ .main-page__wrapper .topbar{
    left: 240px;
    width: calc(100% - 240px);
  }
}