.topbar {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  height: 59px;
  background-color: #fff;
  box-shadow: 0 8px 10px 0 rgba(183, 192, 206, 0.2);
  position: fixed;
  left: 69px;
  width: calc(100% - 69px);
  z-index: 1;
  transition: left .15s ease-in-out, width .15s ease-in-out;

  &__link {
    &--goto {
      color: black;

      &:visited {
        color: black;
      }

      svg {
        margin-right: 5px;
      }
    }
    
  }

  &__btn {

    &--logout {
      margin-left: auto;
    }

    &:active {
      transform: translateX(-1px);
    }
  }
}