.contact-card{
  position: absolute;
  background-color: #e8f6ff;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  top: 73px;
  right: 16px;
  z-index: 5;
  border-radius: 4px;
  padding: 10px;
  display: flex;

  &__left-container{
    width: 150px;
  }
  &__right-container{
    width: 250px;
    padding-left: 15px;
  }
  &__avatar{
    width: 100%;
    display: flex;
    justify-content: center;

    .lazy-load__wrap{
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
    }
  }
  &__name{
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
  }
  &__info-block{
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  &__info-block-title{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 5px;
  }
  &__info-block-item{
    font-size: 14px;
    margin-top: 5px;
    display: flex;

    &:nth-child(2) {
      margin-top: 10px;
    }
    &--active{
      color: #727cf5;
    }
  }
  &__info-item-icon{
    margin-right: 15px;
    color: #808080;
  }
  &__tags-wrap{
    display: flex;
    flex-wrap: wrap;
  }
  &__tag{
    border-radius: 4px;
    padding: 2px 5px 1px;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #e6e6e6;

    &:last-child{
      margin-right: 0;
    }
  }
}