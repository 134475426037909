.global-preloader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__wrap {
    width: 160px;
    height: 160px;
  }
}