.calendar {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  height: 335px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 20px 0;
  background: #fff;

  &__close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 3px;
    font-weight: 14px;
    color: #ff3366;
  }

  .react-calendar {
    position: relative;
    top: 0px;
    width: 330px;
    max-width: none;
    height: 100%;
    padding: 0 10px;
    border: none;
    border-radius: 4px 0 0 4px;
    font-family: Overpass, sans-serif;

    &__tile {
      font-size: 14px;
      padding: 8px;
      border-radius: 4px;

      &--active {
        background: #727cf5;

        &:hover {
          background: #727cf5;
        }

        &:focus {
          background: #727cf5;
        }
      }

      &:disabled {
        display: none;
      }
    }

    &__navigation {
      margin-top: 10px;

      &__label {
        font-weight: 600;
      }

      &__arrow {
        font-weight: 600;
      }

      button[disabled] {
        opacity: 0;
        cursor: default;
      }
    }

    &__month-view__weekdays__weekday {
      text-decoration: none;
      text-transform: none;
      font-size: 14px;
      font-weight: 400;

      abbr {
        text-decoration: none;
      }
    }
  }
}

.calendar-menu {
  position: relative;
  top: 0;
  height: 100%;
  width: 150px;
  background: #fff;
  border-left: 1px solid lightgray;
  margin: 0;
  padding: 10px;
  list-style: none;
  border-radius: 0 4px 4px 0;

  &__item {
    position: relative;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding: 5px 6px 6px 5px;
    margin-bottom: 5px;
    border: 1px solid #e4e9ef;
    border-radius: 4px;
    text-align: right;
    line-height: 1;

    &:hover {
      background-color: #e8ebf1;
    }

    &--active {
      background-color: #727cf5;
      color: white;

      &:hover {
        background-color: #727cf5;
      }
    }

    &-button {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      width: 25px;
      border-radius: 4px 0 0 4px;

      &:hover {
        background-color: #616bd4;
      }

      .calendar-menu__item--active & {
        display: flex;
      }
    }
  }
}